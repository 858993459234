.react-calendar {
  font-size: initial;
}

.highlighter {
  background: #f8ffb4 !important;
  transition: 0.25s ease-in-out;
}

@media (prefers-color-scheme: dark) { 

  .react-date-picker__calendar-button__icon {
    stroke: white;
  }

  .react-calendar {
    background-color: rgb(55, 65, 81);
  }

  .highlighter {
    background-color: #62f020 !important;
  }

}
